import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import { useMyProfile, useUserAddons, useUserId, useUserPermissions } from '@/features/auth'
import { useMyCompany } from '@/features/company'
import { ModalTaskIssues, useIssuesByTaskId } from '@/features/issues'
import { useGeneratePdfFromDefinition } from '@/features/pdf'
import { useTemplate } from '@/features/templates'
import {
  ValidationRequest,
  ValidationRequestStatus,
  useValidationsRequestByEntityId,
} from '@/features/validationRequests'
import { downloader } from '@/helpers/downloader'
import { useToggle } from '@/hooks/use-toggle'
import { TAB_INDEX } from '@/pages/tasks/TasksList/TasksListPage'
import { useIssueStatusGroups } from '@/pages/tasks/TasksList/hooks'
import {
  AlertConfirm,
  COLOR,
  HorizontalLinkCard,
  Modal,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import {
  BadgeCheckIcon,
  ClockIcon,
  DownloadIcon,
  DuplicateIcon,
  ExclamationIcon,
  EyeIcon,
  MailIcon,
  PencilAltIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDeleteTask, useGenerateTaskReport, useTaskById, useUpdateAllTasks } from '../../hooks'
import { TaskStatus } from '../../interfaces'
import { ModalTaskActivity } from '../ModalTaskActivity'
import { ModalTaskEmail } from '../ModalTaskEmail'
import { TaskManagerContext, taskManagerContext } from './context'
import { getIssueModalMode } from './utils'

const { Provider } = taskManagerContext

enum ModalTrigger {
  MANAGE_TASK,
  MANAGE_TASK_ISSUES,
}

export const TaskManager = ({
  children,
  activeTab,
}: {
  children: ReactNode
  activeTab: TAB_INDEX
}) => {
  const { t } = useTranslation('nsTasksListPage')
  const analytics = useAnalytics()
  const history = useHistory()
  const userAddons = useUserAddons()
  const userPermissions = useUserPermissions()
  const userId = useUserId()
  const { profile } = useMyProfile()
  const { company } = useMyCompany()
  const [selectedTaskId, setTaskSelected] = useState<string | undefined | null>()

  const customPermissions = useMemo(() => {
    return profile?.addons.custom
  }, [profile?.addons.custom])

  const { task } = useTaskById(selectedTaskId || '', {
    enabled: !!selectedTaskId,
  })

  const { validationRequests } = useValidationsRequestByEntityId(selectedTaskId || '', {
    enabled: !!selectedTaskId,
  })

  const lastValidationRequest = useMemo<ValidationRequest | undefined>(
    () =>
      validationRequests.sort(
        (requestA, requestB) =>
          new Date(requestB.createdAt).getTime() - new Date(requestA.createdAt).getTime()
      )[0],
    [validationRequests]
  )

  const hasValidationRequestPending: boolean =
    lastValidationRequest?.status === ValidationRequestStatus.PENDING

  const hasUserValidationRequestPending: boolean =
    hasValidationRequestPending &&
    validationRequests[validationRequests.length - 1].reviewers.some(
      reviewer => reviewer.id === userId
    )

  const { template } = useTemplate(task?.formId || '', 'form', {
    enabled: !!task,
  })

  const { issues } = useIssuesByTaskId(selectedTaskId || '', {
    enabled: !!selectedTaskId,
  })

  const [manageOpen, toggleManageOpen] = useToggle(false)
  const [isModalEmailShow, toggleModalEmail] = useToggle(false)
  const [manageIssuesShow, toggleManageIssuesShow] = useToggle(false)
  const [isModalTaskActivityShow, toggleModalTaskActivity] = useToggle(false)
  const [confirmDeletionOpen, toggleConfirmDeletion] = useToggle(false)
  const [trigger, setTrigger] = useState<ModalTrigger | null>(null)

  const { generateTaskReport } = useGenerateTaskReport()
  const { generatePdfFromDefinitionAsync } = useGeneratePdfFromDefinition()
  const { deleteTask } = useDeleteTask()
  const { updateAllTasks } = useUpdateAllTasks({
    silently: true,
  })
  const { issueStatusGroups } = useIssueStatusGroups()

  const downloadReport = useCallback(
    async (taskId: string) => {
      try {
        const taskReportDefinition = await generateTaskReport(taskId)
        const taskReportFilename = t('filename', {
          templateName: task?.formName,
          date: new Date(task?.dueDate || ''),
        })

        const taskReport = await generatePdfFromDefinitionAsync({
          pdfDefinition: JSON.stringify(taskReportDefinition),
          filename: taskReportFilename,
        })

        if (!taskReport) return

        downloader(taskReport?.blob, 'application/pdf', taskReportFilename)

        analytics.track('CUSTOMER_GENERATE_TASK_REPORT', {
          From: 'Task',
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        analytics.track('CUSTOMER_GENERATE_TASK_REPORT_ERROR', {
          From: 'Task',
          ...customErrorToTrack(error?.response?.data, error?.response?.status),
        })
      }
    },
    [
      generateTaskReport,
      analytics,
      generatePdfFromDefinitionAsync,
      t,
      task?.formName,
      task?.dueDate,
    ]
  )

  const handleToggleModalTaskActivity = () => {
    toggleModalTaskActivity(false)
    toggleManageOpen(true)
  }

  const handleToggleModalTaskIssues = () => {
    toggleManageIssuesShow(false)
    if (trigger === ModalTrigger.MANAGE_TASK) {
      toggleManageOpen(true)
    }
    setTrigger(null)
  }

  const handleDeleteConfirm = () => {
    toggleConfirmDeletion()
    if (!selectedTaskId || !task) return

    deleteTask(task)
  }
  const handleDeleteCancel = () => {
    setTaskSelected(undefined)
    toggleConfirmDeletion()
  }

  const value = useMemo<TaskManagerContext>(
    () => ({
      openManager(taskId: string) {
        setTaskSelected(taskId)
        toggleManageOpen()
        setTrigger(ModalTrigger.MANAGE_TASK)
      },
      reviewTask(taskId: string) {
        analytics.track('ACTION_CLICK_REVIEW', {
          Type: 'Task',
          Name: template?.name,
        })
        history.push(`/tasks/${taskId}/validate`)
      },
      manageTaskIssues(taskId: string) {
        setTaskSelected(taskId)
        toggleManageIssuesShow()
        setTrigger(ModalTrigger.MANAGE_TASK_ISSUES)
        analytics.track('ACTION_CLICK_TASK_MANAGEMENT')
      },

      completeTaskBulk: taskIds => {
        analytics.track('ACTION_CLICK_MANAGE_TASK', {
          Action: 'Bulk complete',
        })
        history.push(
          `/tasks/bulk-edit?${new URLSearchParams(taskIds.map(taskId => ['taskIds[]', taskId]))}`
        )
      },

      requestValidationBulk: taskIds => {
        analytics.track('ACTION_CLICK_MANAGE_TASK', {
          Action: 'Bulk request validation',
        })
        history.push(
          `/tasks/validation-requests/create?${new URLSearchParams(
            taskIds.map(selectedTaskId => ['taskIds[]', selectedTaskId])
          )}`
        )
      },
      reviewTaskBulk: taskIds => {
        analytics.track('ACTION_CLICK_MANAGE_TASK', {
          Action: 'Bulk review validation',
        })
        history.push(
          `/tasks/bulk-validate?${new URLSearchParams(
            taskIds.map(taskId => ['taskIds[]', taskId])
          )}`
        )
      },
      closeTaskBulk: taskIds => {
        analytics.track('ACTION_CLICK_MANAGE_TASK', {
          Action: 'Bulk close',
        })
        updateAllTasks({
          tasks: taskIds.map(taskId => ({ id: taskId })),
          status: TaskStatus.CLOSED,
        })
      },
    }),
    [toggleManageOpen, analytics, template?.name, history, toggleManageIssuesShow, updateAllTasks]
  )

  const handleToggleManageOpen = () => {
    if (manageOpen) {
      setTrigger(null)
    }

    toggleManageOpen()
  }
  return (
    <Provider value={value}>
      {children}

      {/* Modal Manage Task */}
      <Modal show={manageOpen} toggle={handleToggleManageOpen} size={SIZE.LARGE} dismissButton>
        <Modal.Title>{t('manage.title')}</Modal.Title>
        <Modal.Body>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
            {!hasValidationRequestPending && (
              <>
                {task?.status === TaskStatus.CLOSED ? (
                  <>
                    <HorizontalLinkCard
                      title={t('manage.report.title').toString()}
                      description={t('manage.report.description').toString()}
                      color={COLOR.GREEN}
                      IconComponent={DownloadIcon}
                      onClick={() => {
                        if (!selectedTaskId) return
                        analytics.track('ACTION_CLICK_MANAGE_TASK', {
                          Action: 'Generate report',
                        })
                        downloadReport(selectedTaskId)
                        toggleManageOpen(false)
                      }}
                    />
                    {userPermissions.tasks.write && (
                      <HorizontalLinkCard
                        title={t('manage.edit.title').toString()}
                        description={t('manage.edit.description').toString()}
                        color={COLOR.YELLOW}
                        IconComponent={PencilAltIcon}
                        onClick={() => {
                          analytics.track('ACTION_CLICK_MANAGE_TASK', {
                            Action: 'View and Edit',
                          })
                          history.push(`/tasks/${selectedTaskId}/edit`)
                        }}
                      />
                    )}
                  </>
                ) : (
                  <HorizontalLinkCard
                    title={t('manage.complete.title').toString()}
                    description={t('manage.complete.description').toString()}
                    color={COLOR.YELLOW}
                    IconComponent={PencilAltIcon}
                    onClick={() => {
                      if (!selectedTaskId) return
                      analytics.track('ACTION_CLICK_MANAGE_TASK', {
                        Action: 'Complete',
                      })
                      const params = new URLSearchParams({
                        task: selectedTaskId,
                      })

                      if (userPermissions.tasks.write) {
                        params.append('step', '1')
                      }
                      history.push(`/tasks/create?${params}`)
                    }}
                  />
                )}
              </>
            )}

            {hasValidationRequestPending && company?.usersCanSeeTasksInReview && (
              <HorizontalLinkCard
                title={t('manage.view.title').toString()}
                description={t('manage.view.description').toString()}
                color={COLOR.YELLOW}
                IconComponent={EyeIcon}
                onClick={() => {
                  analytics.track('ACTION_CLICK_MANAGE_TASK', {
                    Action: 'View',
                  })
                  history.push(`/tasks/${selectedTaskId}/view`)
                }}
              />
            )}

            {!hasValidationRequestPending && userPermissions.tasks?.write && (
              <HorizontalLinkCard
                title={t('manage.duplicate.title').toString()}
                description={t('manage.duplicate.description').toString()}
                color={COLOR.GREEN}
                IconComponent={DuplicateIcon}
                onClick={() => {
                  if (!selectedTaskId) return
                  analytics.track('ACTION_CLICK_MANAGE_TASK', {
                    Action: 'Duplicate',
                  })
                  history.push(`/tasks/create?${new URLSearchParams({ presetId: selectedTaskId })}`)
                }}
              />
            )}

            {userAddons?.departments.enabled &&
              !hasValidationRequestPending &&
              userPermissions.tasks?.write && (
                <HorizontalLinkCard
                  title={t('manage.validationRequest.title').toString()}
                  description={t('manage.validationRequest.description').toString()}
                  color={COLOR.GREEN}
                  IconComponent={BadgeCheckIcon}
                  onClick={() => {
                    if (!selectedTaskId) return
                    analytics.track('ACTION_CLICK_MANAGE_TASK', {
                      Action: 'Validation request',
                    })
                    history.push(
                      `/tasks/validation-requests/create?${new URLSearchParams({
                        'taskIds[]': selectedTaskId,
                      }).toString()}`
                    )
                  }}
                />
              )}

            {task?.status === TaskStatus.CLOSED &&
              !hasValidationRequestPending &&
              userPermissions.tasks.write && (
                <HorizontalLinkCard
                  title={t('manage.email.title').toString()}
                  description={t('manage.email.description').toString()}
                  color={COLOR.BLUE}
                  IconComponent={MailIcon}
                  onClick={() => {
                    analytics.track('ACTION_CLICK_MANAGE_TASK', {
                      Action: 'Send report via email',
                    })
                    toggleManageOpen(false)
                    toggleModalEmail(true)
                  }}
                />
              )}

            {issues.length > 0 && !hasValidationRequestPending && (
              <HorizontalLinkCard
                title={t('manage.manageIssues.title').toString()}
                description={t('manage.manageIssues.description').toString()}
                color={COLOR.GREEN}
                IconComponent={ExclamationIcon}
                enableNotificationDot={issues.some(issue =>
                  issueStatusGroups.CLOSED.includes(issue.status)
                )}
                onClick={() => {
                  analytics.track('ACTION_CLICK_MANAGE_TASK', {
                    Action: 'Manage incidents',
                  })
                  toggleManageOpen(false)
                  toggleManageIssuesShow()
                }}
              />
            )}

            {hasUserValidationRequestPending && (
              <HorizontalLinkCard
                title={t('manage.review.title').toString()}
                description={t('manage.review.description').toString()}
                color={COLOR.PURPLE}
                IconComponent={EyeIcon}
                onClick={() => {
                  analytics.track('ACTION_CLICK_MANAGE_TASK', {
                    Action: 'Review',
                  })
                  history.push(`/tasks/${selectedTaskId}/validate`)
                  toggleManageOpen(false)
                }}
              />
            )}

            <HorizontalLinkCard
              title={t('manage.taskActivity.title').toString()}
              description={t('manage.taskActivity.description').toString()}
              color={COLOR.PURPLE}
              IconComponent={ClockIcon}
              onClick={() => {
                analytics.track('ACTION_CLICK_MANAGE_TASK', {
                  Action: 'Task activity',
                })
                toggleManageOpen(false)
                toggleModalTaskActivity(true)
              }}
            />

            {userPermissions.tasks?.write &&
              (profile?.companyOwner ||
                task?.assignedTo === profile?.user.id ||
                customPermissions?.usersCanSeeAllTasks) && (
                <HorizontalLinkCard
                  title={t('manage.delete.title').toString()}
                  description={t('manage.delete.description').toString()}
                  color={COLOR.RED}
                  IconComponent={TrashIcon}
                  onClick={() => {
                    analytics.track('ACTION_CLICK_MANAGE_TASK', {
                      Action: 'Delete',
                    })
                    toggleManageOpen(false)
                    toggleConfirmDeletion()
                  }}
                />
              )}
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal send report via email */}
      <ModalTaskEmail show={isModalEmailShow} toggle={toggleModalEmail} task={task} />

      {/* Modal to manage task issues */}
      <ModalTaskIssues
        show={manageIssuesShow}
        toggle={handleToggleModalTaskIssues}
        taskId={selectedTaskId || null}
        issueModalMode={getIssueModalMode(activeTab)}
      />
      {/* Modal to see task activity */}
      <ModalTaskActivity
        show={isModalTaskActivityShow}
        toggle={handleToggleModalTaskActivity}
        taskId={selectedTaskId || null}
      />

      {/* Modal confirmation deletion */}
      <AlertConfirm
        title={t('deletionConfirm.title')}
        description={t('deletionConfirm.description')}
        confirmText={t('deletionConfirm.confirm')}
        cancelText={t('deletionConfirm.cancel')}
        show={confirmDeletionOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </Provider>
  )
}
